import React from "react"
import tw, { css } from "twin.macro"
import { navigate } from "gatsby"
import { useQuery, useMutation } from "@apollo/client"
import queryString from "query-string"

import { Localization } from "@src/localization"
import BlueBg from "@src/assets/homeBlueBg.jpg"
import HomeEmail from "@src/assets/homeEmail.png"
import HomeEmailSample from "@src/assets/homeEmailSample.png"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import CustomModal from "./modal"
import {
  navigationRoutes,
  PageOptions,
} from "@src/localization/en/createCampaign"
import Loading from "@src/components/loading"
import { GET_USER, User, GET_STARTED } from "@src/queries/user"

enum ModalType {
  gettingStarted = "gettingStarted",
  gladToBeBack = "gladToBeBack",
}

const HomeSection = (): React.ReactElement => {
  const homeContent = React.useContext(Localization).homePage
  const getStartedContent = React.useContext(Localization)
    .homePageGetStartedModal
  const gladBackContent = React.useContext(Localization).homePageGladBackModal

  // modal to show
  const [modalType, setModalType] = React.useState<ModalType | null>(null)

  // get user
  const getUserResp = useQuery(GET_USER)

  // get started mutation
  const [getStartedMutation] = useMutation(GET_STARTED)

  // parse url for query params
  const parsed = queryString.parse(
    typeof window !== `undefined` ? window.location.search : ""
  )
  const isExternal = parsed && parsed.source && parsed.source === "external"

  // set glad to back modal to show
  React.useEffect(() => {
    if (getUserResp.data) {
      const user =
        getUserResp && getUserResp.data && (getUserResp.data.user as User)
      if (user && user.user.hasViewedGetStarted && isExternal) {
        // old user and source is external
        setModalType(ModalType.gladToBeBack)
      }
    }
  }, [getUserResp])

  // show loading
  if (!getUserResp || !getUserResp.data) {
    return <Loading withOverlay />
  }

  // user
  const user =
    getUserResp && getUserResp.data && (getUserResp.data.user as User)

  return (
    <div
      css={[
        tw`flex flex-col bg-bottom bg-cover bg-no-repeat overflow-hidden`,
        css`
          background-image: url(${BlueBg});
        `,
      ]}
    >
      {/* getting started modal */}
      <CustomModal
        content={{
          ...getStartedContent,
          footerBtn1Link: navigationRoutes[PageOptions.PICKTEMPLATE],
          footerBtn2Link: navigationRoutes[PageOptions.CAMPAIGNS],
        }}
        isVisible={modalType === ModalType.gettingStarted}
        onCancel={() => setModalType(null)}
        onBtnClick={() => {
          getStartedMutation({
            variables: {
              body: {},
            },
            refetchQueries: [
              {
                query: GET_USER,
              },
            ],
          })
        }}
      />

      {/* glad to be back modal */}
      <CustomModal
        content={{
          ...gladBackContent,
          footerBtn1Link: navigationRoutes[PageOptions.PICKTEMPLATE],
          footerBtn2Link: navigationRoutes[PageOptions.CAMPAIGNS],
        }}
        isVisible={modalType === ModalType.gladToBeBack}
        onCancel={() => setModalType(null)}
      />

      {/* first section */}
      <div tw="flex py-12 pl-32 pr-8">
        <div tw="w-6/12">
          <h1 css={tw`text-white text-4xl pb-4 font-bold`}>
            {homeContent.heading}
          </h1>
          <h2 tw="text-white text-base">
            <span tw="font-bold">{homeContent.descriptionLine1}</span>
            {homeContent.descriptionLine2}
          </h2>
          <CustomButton
            color={ButtonColors.orange}
            customCss={tw`mt-10 px-20! text-base! font-bold!`}
            onClick={() => {
              if (user.user.hasViewedGetStarted) {
                // old user
                navigate(navigationRoutes[PageOptions.CAMPAIGNS])
              } else {
                // new user
                setModalType(ModalType.gettingStarted)
              }
            }}
          >
            {homeContent.getStarted}
          </CustomButton>
        </div>
        <div tw="w-6/12 flex items-end justify-center">
          <img
            src={HomeEmail}
            css={[
              css`
                max-height: 14rem;
              `,
              tw`mr-6`,
            ]}
          />
        </div>
      </div>

      {/* second section */}
      <div
        tw="flex pl-32 pr-8"
        css={[
          css`
            background-color: rgba(0, 0, 0, 0.07);
          `,
        ]}
      >
        <div tw="w-6/12 pr-6 py-16">
          <div tw="bg-gray-400 px-6 py-8 rounded-2xl border-solid border-white border-2 text-base">
            {homeContent.reachOut}
          </div>
          <div tw="text-white text-2xl pt-16 font-bold">
            {homeContent.benefitLine1} <br />
            {homeContent.benefitLine2}
          </div>
        </div>
        <div tw="w-7/12 relative">
          <img
            src={HomeEmailSample}
            css={[
              tw`absolute z-10 right-0`,
              css`
                bottom: -54px;
              `,
            ]}
          />
        </div>
      </div>
    </div>
  )
}

export default HomeSection
