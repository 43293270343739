import React from "react"

import Layout from "@src/components/layout"
import HomeSection from "@src/sections/home"

const Home = (): React.ReactElement => {
  return (
    <Layout>
      <HomeSection />
    </Layout>
  )
}

export default Home
