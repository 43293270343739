import React from "react"
import tw, { css } from "twin.macro"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import { navigate } from "gatsby"
import { HomePageGetStartedModal } from "@src/localization/en/homePageGetStartedModal"
import CustomModal from "@src/components/ui/modal"

interface ContentProps {
  content: HomePageGetStartedModal & {
    footerBtn1Link: string
    footerBtn2Link: string
  }
  onBtnClick?: any
}

const Title = ({ content }: ContentProps) => (
  <CustomModal.Title heading={content.heading}>
    <span css={tw`text-orange-1 text-xs font-bold`}>
      {content.headingDescription}
    </span>
  </CustomModal.Title>
)

const Footer = ({ content, onBtnClick }: ContentProps) => (
  <div tw="flex">
    <CustomButton
      color={ButtonColors.orange}
      customCss={[
        tw`w-1/2 border-none! py-3! justify-center`,
        css`
          border-radius: 0px 0px 0px 20px !important;
        `,
      ]}
      onClick={() => {
        navigate(content.footerBtn1Link)
        onBtnClick && onBtnClick()
      }}
    >
      {content.footerBtn1}
    </CustomButton>
    <VerticalBorder customCss={tw`border-gray-200`} />
    <CustomButton
      color={ButtonColors.orange}
      customCss={[
        tw`w-1/2 border-none! ml-0! py-3! justify-center`,
        css`
          border-radius: 0px 0px 20px 0px !important;
        `,
      ]}
      onClick={() => {
        navigate(content.footerBtn2Link)
        onBtnClick && onBtnClick()
      }}
    >
      {content.footerBtn2}
    </CustomButton>
  </div>
)

const TopBorder = () => <div tw="border-t border-gray-400 w-8"></div>
const VerticalBorder = ({
  customCss = "border-gray-400",
}: {
  customCss?: any
}) => <div css={[tw`border-r`, customCss]}></div>

const Section = ({
  title,
  subTitle,
  description,
}: {
  title: string
  subTitle: string
  description: string
}) => (
  <div tw="flex flex-col items-center px-4 py-6 justify-end w-1/2">
    <h3 css={tw`text-black text-sm text-center font-bold`}>{title}</h3>
    <p tw="text-black text-xs">{subTitle}</p>
    <div tw="text-2xs border border-gray-400 p-2 text-center mt-3 mb-3 text-black mx-2">
      {description}
    </div>
  </div>
)

interface CustomModalProps extends ContentProps {
  onCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  isVisible: boolean
}

const Modal = ({
  content,
  isVisible,
  onCancel,
  onBtnClick,
}: CustomModalProps): React.ReactElement => (
  <CustomModal
    title={<Title content={content} />}
    isVisible={isVisible}
    onCancel={onCancel}
    footer={<Footer content={content} onBtnClick={onBtnClick} />}
  >
    <p
      css={[
        tw`text-black text-sm`,
        css`
          padding: 16px 24px 10px 24px;
        `,
      ]}
      dangerouslySetInnerHTML={{
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        __html: content.description,
      }}
    ></p>
    <div tw="flex mt-3">
      <TopBorder />
      <Section
        title={content.section1Title}
        subTitle={content.section1SubTitle}
        description={content.section1Description}
      />
      <TopBorder />
      <VerticalBorder />
      <TopBorder />
      <Section
        title={content.section2Title}
        subTitle={content.section2SubTitle}
        description={content.section2Description}
      />
      <TopBorder />
    </div>
  </CustomModal>
)

export default Modal
